<template>
    <div :class="$style['tabs']" class="course-tab">
        <div class="nav wrapper">
            <el-tabs v-model="activeName" @tab-click="handleTabClick">
                <el-tab-pane :label="item.label" :name="item.name" v-for="item in navData" v-bind:key="item.name"></el-tab-pane>
            </el-tabs>
        </div>
    </div>
    <div class="wrapper">
        <el-card class="filter-box">
            <el-row>
                <el-col :span="6">
                    <div class="filter-items clearfix" v-if="activeName === '1'">
                        <div class="label">教练姓名：</div>
                        <div class="filter-item">
                            <el-input
                                placeholder="请输入教练姓名"
                                v-model="realname"
                                size="small"
                                class="search"
                                @keydown.enter="searchQuery"
                            >
                            </el-input>
                        </div>
                    </div>
                </el-col>
                <el-col :span="18">
                    <div class="filter-items clearfix" v-if="activeName === '1'">
                        <div class="label">证书类型：</div>
                        <div class="filter-item">
                            <el-select
                                v-model="certType"
                                placeholder="证书类型"
                                class="search"
                                size="small"
                                @change="searchQuery"
                            >
                                <el-option v-for="item in certTypeOptions" :key="item.label" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <el-button type="primary" size="small" style="padding-top: 5px; margin-left: 20px;" @click="searchQuery">查询</el-button>
                        <el-button type="primary" size="small" style="padding-top: 5px;" @click="searchReset">重置</el-button>
                    </div>
                </el-col>
            </el-row>
<!--            <div class="filter-items clearfix" v-if="activeName === '1'">-->
<!--                <div>-->
<!--                    -->
<!--                </div>-->
<!--                <div>-->
<!--                    -->
<!--                </div>-->
<!--            </div>-->
        </el-card>
        <div class="table-list" v-if="activeName === '1'">
            <el-table
                stripe
                style="width: 100%"
                header-cell-style="background-color: #eaeffd;
                          text-align: center;
                          font-weight: bold;
                          color: #333;"
                :data="listDatas.records"
                v-if="listDatas.records && listDatas.records.length > 0"
            >
                <el-table-column prop="numId" label="编号" width="50"></el-table-column>
                <el-table-column prop="realname" label="教练姓名" width="160"></el-table-column>
                <el-table-column prop="certNo" label="证书编号"></el-table-column>
                <el-table-column prop="certType" label="证书类型"></el-table-column>
                <el-table-column prop="issueDate" label="颁发日期" width="160"></el-table-column>
            </el-table>
            <el-empty description="暂无数据" v-else></el-empty>
            <div class="pagination">
                <el-pagination
                    @current-change="handleCurrentChange1"
                    v-model:currentPage="pageNo"
                    background
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="listDatas.total"
                    class="page-item"
                    v-if="listDatas.records && listDatas.records.length > 0"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import {ElCol, ElRow} from "element-plus";

export default {
    components: {ElCol, ElRow},
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            activeName: '1', // tab 选中
            // tab 数据
            navData: [
                { label: '教练列表', name: '1' },
            ],
            // 证书类型
            certTypeOptions: [],
            // 列表数据
            listDatas: [],
            // 我的作业课程选中id
            realname: '',
            certType: '',
        };
    },
    created() {
        this.activeName = '1';
    },
    mounted() {
        console.log("here", this.activeName)
        this.activeName = '1';
        this.queryDict();
        // this.queryData();
    },
    methods: {
        searchQuery() {
            this.queryData();
        },
        searchReset() {
            this.realname = '';
            this.certType = '';
            this.pageNo = 1;
            this.queryData();
        },
        // 获取字典数据
        queryDict() {
            const that = this;
            this.$request
                .get('/dict/getDictItems/cert_type')
                .then(function(response) {
                    const { data = {} } = response.data || {};
                    if (that.certTypeOptions.length < 1) {
                        that.queryData();
                    }
                    that.certTypeOptions = data;
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        // 获取作业数据
        queryData() {
            const that = this;
            this.$request
                .get(`/researchTrainingCertificate/query?pageNo=${this.pageNo}&pageSize=${this.pageSize}${this.realname ? '&realname=' + this.realname : ''}${this.certType ? '&certType=' + this.certType : ''}`)
                .then(function(response) {
                    const { data = {} } = response.data || {};
                    const newDatas = data.records.map((item, index) => {
                        item.numId = index + 1;
                        item.certType = that.certTypeOptions.find((i) => i.value === item.certType)?.label;
                        // item.certType = item.certType === '1' ? '初级证书' : item.certType === '2' ? '中级证书' : '高级证书';
                        return item;
                    });
                    that.listDatas = { ...data, records: newDatas };
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        handleCurrentChange1(val) {
            this.pageNo = val;
            this.queryData();
        },
        handleTabClick(tab) {
            this.activeName = tab.props.name;
            this.pageNo = 1;
            this.realname = '';
            this.certType = '';
        },
    },
};
</script>
<style lang="scss" scoped>
.table-list {
    min-height: 400px;
    .el-table {
        margin-bottom: 0;
    }
}
</style>
<style lang="scss" module>
@import 'index.scss';
</style>
