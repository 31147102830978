
import { Options, Vue } from 'vue-class-component';
import Header from '@/components/public/header.vue'; // @ is an alias to /src
import Footer from '@/components/public/footer.vue'; // @ is an alias to /src

@Options({
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      themeColor: '',
    };
  },
  mounted(){
    this.getThemeColor();
    window.document.title = 'NOI 福建';
  },
  methods:{
    getThemeColor: function () {
      const that = this;
      this.$request
        .get(`/studySlideshow/slideshow`)
        .then(function(response: { data: { data: { themeColor: ''}}}) {
          that.themeColor = response.data.data.themeColor;
          switch(that.themeColor){
            case "a":
              document.documentElement.style.setProperty('--color-primary', 'rgb(109, 122, 255)');
              break;
            case "b":
              document.documentElement.style.setProperty('--color-primary', 'rgb(110, 220, 214)');
              break;
            case "c":
              document.documentElement.style.setProperty('--color-primary', 'rgb(184, 96, 223)');
              break;
            case "d":
              document.documentElement.style.setProperty('--color-primary', 'rgb(111, 186, 112)');
              break;
            case "e":
              document.documentElement.style.setProperty('--color-primary', 'rgb(93, 33, 223)');
              break;
            case "f":
              document.documentElement.style.setProperty('--color-primary', '#1355b3');
              break;
            default:
              break;
          }
        })
        .catch(function(error: any) {
          console.log(error);
        });
    }
  }
})
export default class APP extends Vue {}
