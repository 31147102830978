
import { Options, Vue } from 'vue-class-component';
import AnswerTopTitle from '@/components/panels/AnswerTopTitle.vue';
import AnswerHead from '@/components/panels/AnswerHead.vue';
import SingleChoice from '@/components/questions/SingleChoice.vue';
import MultipleChoice from '@/components/questions/MultipleChoice.vue';
import Completion from '@/components/questions/Completion.vue';
import TF from '@/components/questions/TF.vue';
import QA from '@/components/questions/QA.vue';
import Program from '@/components/questions/Program.vue';
import ProgramPanel from '@/components/panels/Program.vue';
import * as homeworkService from '@/services/homework';
import { ElMessage } from 'element-plus';
import { formatTime } from '@/utils/utils';
import defaultImg from '@/assets/img/user.png';

@Options({
  props: {
    isExam: Boolean,
    isExamAnswer: Boolean,
    isHomeworkDone: Boolean,
    isHomeworkAnswer: Boolean,
  },
  components: {
    AnswerTopTitle,
    AnswerHead,
    SingleChoice,
    MultipleChoice,
    Completion,
    TF,
    QA,
    Program,
    ProgramPanel,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSubmit) {
      next();
      return;
    }
    this.$confirm('您还没有提交试卷，确定要离开吗？', '提示')
      .then(() => {
        next();
      })
      .catch(() => {
        next(false);
      });
  },
  computed: {
    sortStart() {
      return this.size * (this.page - 1) + 1;
    },
    sortEnd() {
      return this.size * this.page;
    },
  },
  watch: {
    page() {
      document.body.scrollIntoView(true);
    },
  },
})
export default class QuestionPaper extends Vue {
  $router: any;
  $store: any;
  $confirm: any;
  isExam!: boolean; // 是否是考试模式
  isExamAnswer!: boolean; // 成绩查询
  isHomeworkDone!: boolean; // 已完成作业查询
  isHomeworkAnswer!: boolean; // 作业答案查询
  localAnswer: any; // 作业答案本地缓存
  isAnswer!: boolean; // 是否是答案模式
  questionDetail: ObjectT = {}; // 题卷详情
  questionContent: ObjectT = {}; // 题目内容
  rollCallStatus: any;
  courseId: any;
  chapterId: any;
  sectionId: any;
  workId: any; // 页面id(试卷或练习卷id)
  id: any;
  page = 1; // 当前页数
  size = 5; // 每页显示的题目数
  maxPage = 1; // 最大页数
  sortStart: any;
  sortEnd: any;
  questionNum = 0; // 大题编号
  questionNumMap = ['一', '二', '三', '四', '五', '六']; // 大题对应的中文序号
  questionTypeNameMap = ['单选题', '多选题', '判断题', '填空题', '简答题', '编程题'];
  countDownTimer: any = null; // 倒计时定时器
  savePaperTimer: any = null; // 保存试卷答题内容定时器
  isLogin = !!localStorage.getItem('token'); // 是否登录
  userInfo = {
    avatar: '',
    realname: '',
    sex: '',
    studyNo: '',
    school: '',
    schoolClassName: '',
  }; // 用户信息
  sideSheetFixed = false; // 左侧答题卡是否固定
  sideSheetPos = {
    x: 0,
    y: 0,
  }; // 左侧答题卡固定的时候的定位位置值
  sideSheetEl: any; // 左侧答题卡ref对象
  sideSheetInitTop = 0; // 左侧答题卡默认top值
  showProgramPanel = false; // 是否显编程题面板
  currProgramData = {}; // 当前编程题数据
  questionId = ''; // 当前编程题id
  isSubmit = false; // 是否提交了卷子
  isChange = false; // 是否修改了答案
  showAnswer = false; // 是否显示答案模式
  answerNum: any = 0; // 试卷类型（正式考卷/模拟）
  defaultImg = defaultImg;
  restrictNum: number | null = null; // 考试时跳出页面的限制(-1为不限制)

  created() {
    this.courseId = this.$route.query.courseId;
    this.chapterId = this.$route.query.chapterId;
    this.sectionId = this.$route.query.sectionId;
    this.workId = this.$route.query.workId;
    this.id = this.$route.query.id;
    this.answerNum = this.$route.query.answerNum ? this.$route.query.answerNum : 0;
    this.isAnswer = this.isHomeworkAnswer;
    this.localAnswer = JSON.parse((window as any).localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId));
    const restrictNumObjStr = localStorage.getItem('restrictNum');
    if (restrictNumObjStr) {
      const restrictNumObj = JSON.parse(restrictNumObjStr);
      if (restrictNumObj[this.workId]) {
        this.restrictNum = restrictNumObj[this.workId];
      }
    }
  }
  async mounted() {
    if (!this.isLogin) {
      // 未登录
      ElMessage.error('请先登录');
      this.showLoginPop();
      return;
    }
    this.getUserInfo();
    await this.getQuestionContent();

    if (!this.questionContent.records || this.isAnswer) {
      // 说明页面报错了或者成绩查询模式允许返回
      this.isSubmit = true; // 允许退出当前页面
    }
    if (this.questionContent.records && this.isExam) {
      this.enableSavePaper();
    }

    this.sideSheetEl = this.$refs.sideSheet;
    if (!this.sideSheetEl) {
      return;
    }
    this.sideSheetInitTop = this.sideSheetEl.getBoundingClientRect().top;
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    // 监听页面滚动
    window.addEventListener('scroll', () => {
      // this.handleSideSheetFixed();
    });

    window.addEventListener('resize', () => {
      this.calcSideSheetPos();
    });
  }
  beforeUnmount() {
    clearInterval(this.countDownTimer);
    clearInterval(this.savePaperTimer);
    this.destroyPageVisibilityChange();
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload(event: any) {
    if (this.isChange) {
      // 有未保存的更改
      const message = '你有未保存的更改，确定要离开吗？';
      event.returnValue = message;
      return message;
    }
  }

  // 获取tab切换事件名
  getTabVisibilityChangeName() {
    let visibilityChange = '';
    const newDocument: any = document;
    if (typeof newDocument.hidden !== 'undefined') {
      visibilityChange = 'visibilitychange';
    } else if (typeof newDocument.mozHidden !== 'undefined') {
      visibilityChange = 'mozvisibilitychange';
    } else if (typeof newDocument.msHidden !== 'undefined') {
      visibilityChange = 'msvisibilitychange';
    } else if (typeof newDocument.webkitHidden !== 'undefined') {
      visibilityChange = 'webkitvisibilitychange';
    }
    return visibilityChange;
  }

  // 移除页面隐藏监听
  destroyPageVisibilityChange() {
    const visibilityChange = this.getTabVisibilityChangeName();
    document.removeEventListener(visibilityChange, this.handleBrowerTabChange, false);
  }

  // 监听当前页面的显示隐藏
  watchPageVisibilityChange() {
    const visibilityChange = this.getTabVisibilityChangeName();
    // 添加监听器
    document.addEventListener(visibilityChange, this.handleBrowerTabChange, false);
  }

  // 处理tab切换的逻辑处理
  handleBrowerTabChange() {
    let hidden = '';
    const newDocument: any = document;
    if (typeof newDocument.hidden !== 'undefined') {
      hidden = 'hidden';
    } else if (typeof newDocument.mozHidden !== 'undefined') {
      hidden = 'mozHidden';
    } else if (typeof newDocument.msHidden !== 'undefined') {
      hidden = 'msHidden';
    } else if (typeof newDocument.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
    }
    if (!newDocument[hidden] && this.restrictNum !== null) {
      const restrictNumObjStr = localStorage.getItem('restrictNum');
      if (restrictNumObjStr) {
        const restrictNumObj = JSON.parse(restrictNumObjStr);
        if (this.restrictNum <= 1) {
          // 自动提交
          this.saveOrCommitPaper(2);
        }
        restrictNumObj[this.workId] -= 1;
        this.restrictNum -= 1;
        localStorage.setItem('restrictNum', JSON.stringify(restrictNumObj));
        ElMessage.info(
          `您已切出${this.questionContent.restrictNum - restrictNumObj[this.workId]}次，剩余${
            restrictNumObj[this.workId]
          }次。${this.restrictNum >= 1 ? '超过次数将' : '已'}自动交卷！`,
        );
      }
    }
  }

  // 检查是否在考试时间内
  checkIsValidExamTime() {
    // 检查浏览器
    // const isChrome = window.navigator.userAgent.indexOf("Chrome") !== -1;
    // console.log('isChrome', isChrome, window.navigator.userAgent)
    // if (!isChrome) {
    //   ElMessage.error('为保证考试正常进行，请使用谷歌浏览器进行考试！');
    //   return false;
    // }
    const startTime = new Date(this.questionContent.startTime).getTime();
    const now = new Date().getTime();
    if (now < startTime) {
      ElMessage.error('考试未开始');
      return false;
    }
    if (now > startTime + this.questionContent.examTime * 60 * 1000) {
      ElMessage.error('考试已结束');
      return false;
    }
    return true;
  }

  // 处理点击返回
  handleGoBack() {
    if (this.isHomeworkAnswer) {
      // 作业查询模式
      this.$router.push('/user/course?type=3');
    } else {
      // 作业模式
      this.$router.push('/user/course?type=3');
    }
  }

  // 启用定时保存答题内容(1分钟保存一次)
  enableSavePaper() {
    this.savePaperTimer = setInterval(() => {
      this.saveOrCommitPaper(1);
    }, 60000);
  }

  // 提交试卷操作
  beforeCommitPaper() {
    let pass = 0;
    for (let i = 0; i < this.questionContent.newQuestion.length; i++) {
      const item = this.questionContent.newQuestion[i];
      if (this.isHomeworkDone) {
        if (!item.answer) {
          // 有题目未作答（已提交作业答案 :item.answer;)
          pass = 1;
          break;
        }
      } else {
        if (this.localAnswer) {
          if (!this.localAnswer[item.id]) {
            // 有题目未作答（未提交作业答案 :this.localAnswer[item.id])
            pass = 1;
            break;
          }
        }
      }
    }
    if (pass) {
      // 有题目未作答
      this.$confirm('您还有题目未作答，确定要提交吗？', '提示')
        .then(() => {
          this.isChange = false;
          this.saveOrCommitPaper(this.id === undefined ? 2 : 1);
        })
        .catch(() => {
          // 取消
        });
    } else {
      this.isChange = false;
      this.saveOrCommitPaper(this.id === undefined ? 2 : 1);
    }
  }

  // 保存或提交答题内容
  async saveOrCommitPaper(type: number) {
    const answer: any = {
      courseId: this.courseId,
      workId: this.workId,
      classId: "null",
      sectionId: this.sectionId,
      workAnswers: {},
    };
    console.log(this.questionContent.newQuestion)
    if (type === 2) {
      if (JSON.parse((window as any).localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId))) {
        this.questionContent.newQuestion.map((item: ObjectT) => {
          const data: any = {
            [item.id]: JSON.parse((window as any).localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId))[item.id] || null,
          };
          console.log(data);
          answer.workAnswers = { ...answer.workAnswers, ...data }
          return item;
        });
      }
      console.log(answer);
    }
    if (type === 1) {
      this.questionContent.newQuestion.map((item: ObjectT) => {
        const data: any = {
          [item.question_id]: item.answer || null,
        };
        console.log(data);
        answer.workAnswers = { ...answer.workAnswers, ...data }
        return item;
      });
      console.log(answer);
    }

    const resp = await homeworkService.submit(answer);
    const data = resp.data;
    console.log(data)
    if (!data.code) {
      this.isSubmit = true;
      if (type === 1) {
        ElMessage.success('保存成功');
      }
      if (type === 2) {
        // this.$router.push('/user/course?type=3');
        ElMessage.success('提交成功');
        // window.close();
      }
    }
  }

  // 处理侧边导航固定
  handleSideSheetFixed() {
    if (!this.sideSheetInitTop && this.sideSheetEl) {
      this.sideSheetInitTop = this.sideSheetEl.getBoundingClientRect().top;
    }
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    this.calcSideSheetPos();
    if (scrollTop > this.sideSheetInitTop) {
      this.sideSheetFixed = true;
    } else {
      this.sideSheetFixed = false;
    }
  }

  // 计算侧边答题卡的定位值
  calcSideSheetPos() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const winW = document.body.clientWidth;
    const winH = document.body.clientHeight;
    const footer: any = document.querySelector('.footer');
    const footerTop = footer.offsetTop;
    this.sideSheetPos.x = (winW - 1200) / 2;
    if (this.sideSheetPos.x < 0) {
      this.sideSheetPos.x = 0;
    }
    if (winH + scrollTop > footerTop - 32) {
      this.sideSheetPos.y = -(winH + scrollTop - footerTop - 32);
    } else {
      this.sideSheetPos.y = 16;
    }
  }
  // 显示登录弹窗
  showLoginPop() {
    this.$store.commit('setLoginVisible', true);
  }

  // 获取用户信息
  getUserInfo() {
    let userInfo = localStorage.getItem('userInfo');
    if (!userInfo) {
      userInfo = '';
    }
    this.userInfo = JSON.parse(userInfo);
  }

  // 调整获取到的题卷数据
  adjustQuestionContent(data: ObjectT) {
    if (data.records && data.records.length > 0) {
      const questionTypeNoMap = ['一', '二', '三', '四', '五', '六']; // 题型序号
      let currNoIndex = 0; // 题型序号索引值
      let totalQuestionIndex = 1; // 当前题目的题号
      data.newQuestion = [];
      const questionObj: ObjectT = {};
      const answerObj: ObjectT = {};
      data.records.map((item: ObjectT, index: number) => {
        questionObj[index] = item;
        return item;
      });
      // if (data.answer) {
      //   data.answer.map((item: ObjectT) => {
      //     answerObj[item.questionId] = item;
      //     return item;
      //   });
      // }
      let questionList = [];
      for (let i = 0; i < data.records.length; i++) {
        if (data.records[i].questionType === 1) {
          questionList.push(data.records[i]);
          if (data.records[i].sortNo === 1) {
            data.singleChoiceIndex = questionTypeNoMap[currNoIndex];
            currNoIndex += 1;
          }
        }
        if (data.records[i].questionType === 2) {
          questionList.push(data.records[i]);
          if (data.records[i].sortNo === 1) {
            data.multipleChoiceIndex = questionTypeNoMap[currNoIndex];
            currNoIndex += 1;
          }
        }
        if (data.records[i].questionType === 3) {
          questionList.push(data.records[i]);
          if (data.records[i].sortNo === 1) {
            data.trueOrFalseIndex = questionTypeNoMap[currNoIndex];
            currNoIndex += 1;
          }
        }
        if (data.records[i].questionType === 4) {
          questionList.push(data.records[i]);
          if (data.records[i].sortNo === 1) {
            data.gapFillingIndex = questionTypeNoMap[currNoIndex];
            currNoIndex += 1;
          }
        }
        if (data.records[i].questionType === 5) {
          questionList.push(data.records[i]);
          if (data.records[i].sortNo === 1) {
            data.questionsIndex = questionTypeNoMap[currNoIndex];
            currNoIndex += 1;
          }
        }
        if (data.records[i].questionType === 6) {
          questionList.push(data.records[i]);
          if (data.records[i].sortNo === 1) {
            data.programmeIndex = questionTypeNoMap[currNoIndex];
            currNoIndex += 1;
          }
        }
      }
      questionList = questionList.map((item: ObjectT, index: number) => {
          const newItem = {
            totalSort: totalQuestionIndex,
            ...item,
            ...questionObj[index],
          };

          if (answerObj[index]) {
            newItem.answer = answerObj[index].answer;
            newItem.studentId = answerObj[index].studentId;

            console.log("11111", answerObj[index]);
            newItem.answerNum = answerObj[index].answerNum;
          }
          totalQuestionIndex += 1;
          return newItem;
      });
      data.newQuestion = data.newQuestion.concat(questionList); // 重新排序后的所有题目数据
      console.log("newQuestion", data.newQuestion);
      console.log(this.courseId);
    }

    if(data.workQuestions && data.workQuestions.length > 0) {
      const questionTypeNoMap = ['一', '二', '三', '四', '五', '六']; // 题型序号
      let currNoIndex = 0; // 题型序号索引值
      let totalQuestionIndex = 1; // 当前题目的题号
      data.newQuestion = [];
      const questionObj: ObjectT = {};
      const answerObj: ObjectT = {};
      data.workQuestions.map((item: ObjectT, index: number) => {
        questionObj[index] = item;
        return item;
      });
      // if (data.answer) {
      //   data.answer.map((item: ObjectT) => {
      //     answerObj[item.questionId] = item;
      //     return item;
      //   });
      // }
      let questionList = [];
      for (let i = 0; i < data.workQuestions.length; i++) {
        if (data.workQuestions[i].questionType === 1) {
          questionList.push(data.workQuestions[i]);
          if (data.workQuestions[i].sortNo === 1) {
            data.singleChoiceIndex = questionTypeNoMap[currNoIndex];
            currNoIndex += 1;
          }
        }
        if (data.workQuestions[i].questionType === 2) {
          questionList.push(data.workQuestions[i]);
          if (data.workQuestions[i].sortNo === 1) {
            data.multipleChoiceIndex = questionTypeNoMap[currNoIndex];
            currNoIndex += 1;
          }
        }
        if (data.workQuestions[i].questionType === 3) {
          questionList.push(data.workQuestions[i]);
          if (data.workQuestions[i].sortNo === 1) {
            data.trueOrFalseIndex = questionTypeNoMap[currNoIndex];
            currNoIndex += 1;
          }
        }
        if (data.workQuestions[i].questionType === 4) {
          questionList.push(data.workQuestions[i]);
          if (data.workQuestions[i].sortNo === 1) {
            data.gapFillingIndex = questionTypeNoMap[currNoIndex];
            currNoIndex += 1;
          }
        }
        if (data.workQuestions[i].questionType === 5) {
          questionList.push(data.workQuestions[i]);
          if (data.workQuestions[i].sortNo === 1) {
            data.questionsIndex = questionTypeNoMap[currNoIndex];
            currNoIndex += 1;
          }
        }
        if (data.workQuestions[i].questionType === 6) {
          questionList.push(data.workQuestions[i]);
          if (data.workQuestions[i].sortNo === 1) {
            data.programmeIndex = questionTypeNoMap[currNoIndex];
            currNoIndex += 1;
          }
        }
      }
      questionList = questionList.map((item: ObjectT, index: number) => {
          const newItem = {
            totalSort: totalQuestionIndex,
            ...item,
            ...questionObj[index],
          };

          if (answerObj[index]) {
            newItem.answer = answerObj[index].answer;
            newItem.studentId = answerObj[index].studentId;

            console.log("11111", answerObj[index]);
            newItem.answerNum = answerObj[index].answerNum;
          }
          totalQuestionIndex += 1;
          return newItem;
      });
      data.newQuestion = data.newQuestion.concat(questionList); // 重新排序后的所有题目数据
      console.log("newQuestion", data.newQuestion);
      console.log(this.courseId);
    }
    return data;
  }

  // 获取题卷内容
  async getQuestionContent() {
    let resp: any = {};
    if (this.isHomeworkDone) {
      // 作业查询
      resp = await homeworkService.studentAnswers(this.id);
    } else {
      // 作业
      console.log("================")
      resp = await homeworkService.startDoHomework(this.courseId, this.chapterId, this.sectionId, this.workId);
    }
    const { data } = resp;
    if (data.code) {
      return;
    }
    const questionContent = data.data;
    this.rollCallStatus = data.msg;
    this.questionContent = this.adjustQuestionContent(questionContent);
    this.maxPage = Math.ceil(this.questionContent.newQuestion.length / this.size);
  }

  // 倒计时
  countDown(initTime: number) {
    let time = initTime;
    clearInterval(this.countDownTimer);
    this.countDownTimer = setInterval(() => {
      time = time - 1;
      if (!time) {
        ElMessage.success('时间已到，试卷已自动提交');
        this.saveOrCommitPaper(2);
        clearInterval(this.countDownTimer);
      }
    }, 1000);
  }

  // 点击上一页
  go2PrevPage() {
    if (this.page > 1) {
      this.page -= 1;
    }
  }
  // 点击下一页
  go2NextPage() {
    if (this.page < this.maxPage) {
      this.page += 1;
    }
  }
  // 点击题号跳到指定题目
  go2Question(sort: number) {
    const page = Math.ceil(sort / this.size);
    if (page !== this.page) {
      this.page = page;
    }
    const el: any = this.$refs['q' + sort];
    if (el) {
      const timer = setTimeout(() => {
        el.scrollIntoView(true);
        clearTimeout(timer);
      }, 50);
    }
  }

  // 处理题目答题变更
  handleAnswerChange(type: number, totalSort: number, value: any) {
    let answer: any = {};
    this.isChange = true;
    if (!this.isHomeworkDone) {
      if (type === 2) {
        this.questionContent.newQuestion.map((item: ObjectT) => {
          const qa: any = {
            [item.id]: this.questionContent.newQuestion[totalSort - 1] === item ? value.join(',') : localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId) ? JSON.parse((window as any).localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId))[item.id] : '',
          };
          answer = { ...answer, ...qa };
          return item;
        });
        localStorage.setItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId, JSON.stringify(answer));
      } else {
        this.questionContent.newQuestion.map((item: ObjectT) => {
          const qa: any = {
            [item.id]: this.questionContent.newQuestion[totalSort - 1] === item ? value : localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId) ? JSON.parse((window as any).localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId))[item.id] : '',
          };
          answer = { ...answer, ...qa };
          return item;
        });
        localStorage.setItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId, JSON.stringify(answer));
      }
    }

    switch (type) {
      case 1:
        this.questionContent.newQuestion[totalSort - 1].status = true;
        this.questionContent.newQuestion[totalSort - 1].answer = value;
        break;
      case 2:
        if (value.length) {
          this.questionContent.newQuestion[totalSort - 1].status = true;
        } else {
          this.questionContent.newQuestion[totalSort - 1].status = false;
        }
        this.questionContent.newQuestion[totalSort - 1].answer = value.join(',');
        break;
      // case 3: {
      //   const newValue: string[] = [];
      //   let answeredNum = 0;
      //   value.split('|').map((item: string) => {
      //     const newItem = item.trim();
      //     newValue.push(newItem);
      //     if (newItem) {
      //       answeredNum += 1;
      //     }
      //   });
      //   if (answeredNum === newValue.length) {
      //     this.questionContent.newQuestion[totalSort - 1].status = true;
      //   } else {
      //     this.questionContent.newQuestion[totalSort - 1].status = false;
      //   }
      //   this.questionContent.newQuestion[totalSort - 1].answer = newValue.join('|');
      //   break;
      // }
      case 4: {
        const newValue = value.trim();
        if (newValue) {
          this.questionContent.newQuestion[totalSort - 1].status = true;
        } else {
          this.questionContent.newQuestion[totalSort - 1].status = false;
        }
        this.questionContent.newQuestion[totalSort - 1].answer = newValue;
        break;
      }
      case 5:
        this.questionContent.newQuestion[totalSort - 1].answer = value;
        this.questionContent.newQuestion[totalSort - 1].status = true;
        break;
      default:
        break;
    }
    this.localAnswer = JSON.parse((window as any).localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId));
    console.log("newQuestion", this.questionContent.newQuestion);
  }

  // 处理点击开始编程
  handleStartProgram(data: ObjectT) {
    this.currProgramData = data;
    this.showProgramPanel = true;
    this.localAnswer = JSON.parse((window as any).localStorage.getItem('work_' + JSON.parse((window as any).localStorage.getItem('userInfo')).id + '_' + this.workId));
    document.body.style.overflow = 'hidden';
  }

  // 处理编程面板关闭
  handleCloseProgramPanel() {
    this.showProgramPanel = false;
    document.body.style.overflow = 'auto';
  }
}
