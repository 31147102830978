<template>
  <div class="clearfix header" :class="$style['header']">
    <div class="wrapper">
      <!--logo替换-->
      <img src="https://cdn2.1717youxue.com/static/noifj/noifj_logo.png" alt="" :class="$style['logo']" />
      <!-- <img src="@/assets/img/logo.png" alt="" :class="$style['logo']" /> -->
      <el-button type="primary" v-if="!isLogin" :class="$style['login']" size="mini" @click="showLoginPop"
        >登录</el-button
      >
      <div :class="$style['user']" v-else>
<!--        <router-link to="/user/course" style="color: rgb(51, 51, 51); font-size:16px; height:50px; line-height:20px; position:relative; top:7px; right: 50px;">我的课程</router-link>-->
        <span style="font-size:16px; height:50px; line-height:20px; position:relative; top:7px;">{{userInfo.realname}}</span>
        <el-dropdown>
          <span>
            <span :class="$style['user-name']"> {{ userInfo.nickname }}</span>
            <span :class="$style['dropdown']">
              <img
                :src="userInfo.avatar ? userInfo.avatar : defaultImg"
                alt=""
                :onerror="`onerror=null;src='${defaultImg}'`"
              />
            </span>
          </span>

          <template #dropdown>
            <el-dropdown-menu :class="$style['dropdown-menu']">
              <el-dropdown-item command="a"><router-link to="/user/create">创作中心</router-link></el-dropdown-item>
              <el-dropdown-item command="a"><router-link to="/user/course?type=1">我的课程</router-link></el-dropdown-item>
                <el-dropdown-item command="a"><router-link to="/user/course?type=2">我的作业</router-link></el-dropdown-item>
              <el-dropdown-item command="a"><router-link to="/user">个人中心</router-link></el-dropdown-item>
              <el-dropdown-item command="b" @click="loginOut"><span>退出</span></el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <el-menu
        :default-active="activeIndex"
        :class="$style['el-menu-demo']"
        mode="horizontal"
        text-color="#333"
        :router="true"
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/news">资讯</el-menu-item>
        <!--logo替换-->
        <el-menu-item index="/teacher">教练</el-menu-item>
        <el-menu-item @click="openWin('https://judge.1717youxue.com/')">测评</el-menu-item>
        <el-menu-item v-if="isLogin" index="/course">我的课程</el-menu-item>
        <!-- <el-menu-item index="/exam">创作中心</el-menu-item>
             <el-menu-item index="/questionbank">题库</el-menu-item>
             <el-menu-item index="/exam">考试</el-menu-item>
             <el-menu-item index="/about">关于</el-menu-item> -->
      </el-menu>
    </div>
  </div>
  <el-dialog
    title="登录"
    v-model="loginVisible"
    width="500px"
    :before-close="handleClose"
    center
    custom-class="login-pop"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-form-item label="用户名：" :label-width="formLabelWidth" class="form-item" prop="username">
        <el-input v-model="ruleForm.username" autocomplete="off" size="medium" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码：" :label-width="formLabelWidth" class="form-item" prop="password">
        <el-input
          v-model="ruleForm.password"
          autocomplete="off"
          size="medium"
          placeholder="请输入密码"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item label="验证码：" :label-width="formLabelWidth" class="code-item form-item" prop="captcha">
        <el-input
          v-model="ruleForm.captcha"
          autocomplete="off"
          size="medium"
          @keydown.enter="submitForm('ruleForm')"
        ></el-input>
        <img :src="rangeImg" alt="" @click="getRandomImage" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">登 录</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import defaultImg from '@/assets/img/user.png';
import { CDNURL } from '@/utils/constants';
import {renderFileUrl} from "@/utils/utils";
export default {
  data() {
    return {
      // 当前页面路径
      activeIndex: '/',

      // 表单数据
      ruleForm: {
        username: '',
        password: '',
        captcha: '',
      },
      // 表单规则
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      // 登录label宽度
      formLabelWidth: '100px',
      // 获取验证码时间搓
      parseDate: '',
      // 验证码
      rangeImg: '',
      // 是否登录
      isLogin: !!localStorage.getItem('token'),
      // 用户数据
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      // 默认头像
      defaultImg: defaultImg,
    };
  },
  computed: {
    // 登录弹窗
    loginVisible() {
      return this.$store.state.loginVisible;
    },
  },
  watch: {
    $route: function() {
      const pathData = this.$route.path.split('/');
      const activePath = pathData.length >= 2 ? `/${pathData[1]}` : '/';
      if (activePath !== '/user') {
          this.activeIndex = activePath;
      }
    },
    loginVisible: function() {
      if (this.loginVisible) {
        this.getRandomImage();
      }
    },
  },
  methods: {
    // 获取登录验证码
    getRandomImage() {
      const timestamp = Date.parse(new Date());
      this.parseDate = timestamp;
      const that = this;
      this.$request
        .get(`/studyStudent/randomImage/${timestamp}`)
        .then(function(response) {
          const { data = {} } = response || {};
          that.rangeImg = data.data || '';
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 提交登录表单
    submitForm(formName) {
      const that = this;
      const Base64 = require('js-base64').Base64;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.password = Base64.encode(this.ruleForm.password);
          this.$request
            .post(`/studyStudent/login`, { ...this.ruleForm, checkKey: this.parseDate })
            .then(function(response) {
              const { data = {} } = response || {};
              if (data.code === 0) {
                const { data = {} } = response.data || {};
                const { userInfo = {} } = data;
                localStorage.setItem('token', data.token);
                const userInfoData = {
                  username: userInfo.username, // 账号
                  realname: userInfo.realname, // 名字
                  avatar: userInfo.avatar, // 头像
                  sex: userInfo.sex, // 性别
                  id: userInfo.id, // id
                  school: userInfo.school, // 学校
                  schoolClassName: userInfo.schoolClassName, // 班级
                  studyNo: userInfo.studyNo, // 学号
                  schoolGradeName: userInfo.schoolGradeName, // 年级
                };
                if (userInfoData.avatar) userInfoData.avatar = renderFileUrl(CDNURL, userInfoData.avatar);
                localStorage.setItem('userInfo', JSON.stringify(userInfoData));
                that.$router.go(0);
                that.$store.commit('setLoginVisible', false);
              }
              if (data.msg === '验证码错误') {
                that.getRandomImage();
                that.ruleForm.captcha = '';
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    // 显示登录弹窗
    showLoginPop() {
      this.$store.commit('setLoginVisible', true);
    },
    // 关闭
    handleClose() {
      this.$store.commit('setLoginVisible', false);
    },
    // 退出
    loginOut() {
      const that = this;
      this.$request
        .post(`/studyStudent/logout`, {})
        .then(function(response) {
          localStorage.clear();
          that.$router.go(0);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    openWin(url) {
      let token = localStorage.getItem('token');
      window.open(url + (token ? '?token=' + token : ''), '_blank');
    },
  },
};
</script>
<style lang="scss">
.header {
  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 2px solid $color-primary;
  }
  .el-menu--horizontal > .el-menu-item {
    margin: 0 27px;
    padding: 0 10px;
    font-size: 16px;
  }
  .el-menu.el-menu--horizontal {
    float: right;
    border-bottom: none;
  }
}
.el-dialog.login-pop {
  border-radius: 10px;
  overflow: hidden;
  .el-dialog__headerbtn {
    .el-dialog__close {
      font-size: 24px;
      color: #fff;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .el-dialog__body {
    padding-bottom: 0;
  }
  .el-dialog__header {
    background-color: $color-primary;
    .el-dialog__title {
      color: #fff;
    }
  }
  .form-item {
    color: #fff;
    .el-input {
      width: 300px;
    }
  }
  .code-item {
    .el-input {
      width: 180px;
      vertical-align: middle;
    }
    img {
      display: inline-block;
      margin-left: 10px;
      height: 36px;
      width: 110px;
      vertical-align: middle;
    }
  }
}
</style>
<style lang="scss" module>
.header {
  position: relative;
  box-shadow: 0 -2px 8px 0 rgba(27, 27, 98, 0.1);
  z-index: 9;
  padding-bottom: 10px;
  background: #fff;
  .login {
    float: right;
    margin-top: 15px;
    margin-left: 100px;
    border-radius: 10px;
    padding: 7px 20px;
  }
}
// logo替换
.logo {
  float: left;
  margin-top: 10px;
  width: 220px;
  height: 44px;
}
// .logo {
//   float: left;
//   margin-top: 7px;
//   width: 275px;
//   height: 47px;
// }
.user {
  float: right;
  margin-left: 100px;
  margin-top: 8px;
  .dropdown {
    display: inline-block;
    margin-top: 12px;
    width: 20px;
    height: 20px;
    border-radius: 40px;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;
  }
  .user-name {
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
    line-height: 40px;
    vertical-align: middle;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.dropdown-menu {
  a,
  span {
    display: block;
    padding: 0 10px;
    color: #333;
    width: 100%;
    height: 100%;
    &:hover {
      color: $color-primary;
    }
  }
}
</style>
